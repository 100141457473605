<template>
  <div class="test">
    <div id="map" class="GvolContainer" ref="map">
      <img
        class="reset"
        src="@/assets/image/reset.png"
        alt=""
        @click="resetMapZoom"
      />
      <!-- 天气 -->
      <MapWeather :showId="showId"></MapWeather>

      <!-- 全部信息  -->
      <MapAllInfo
        :showId="showId"
        :cameraList="cameraList"
        :weatherCount="weatherCount"
      ></MapAllInfo>

      <!-- 设备状态 -->
      <MapEquipment
        :showId="showId"
        :colDataList="colDataList"
        :cameraList="cameraList"
        @changeSwitch="changeSwitch"
      ></MapEquipment>
      <!-- 采集站 -->
      <MapCollection
        :colDataList="colDataList"
        :showId="showId"
      ></MapCollection>

      <!-- 地块 -->
      <MapLands
        :showId="showId"
        :plot_id="plotId"
        :plotForm="plotForm"
      ></MapLands>

      <!-- 摄像头 -->
      <MapCamera
        :showId="showId"
        :videoUrl="videoUrl"
        :cameraTitle="cameraTitle"
      ></MapCamera>

      <div class="map-r">
        <!-- 筛选窗口 -->
        <div class="popup-base">
          <div class="title">筛选条件</div>
          <div class="check-out-div">
            <el-checkbox-group class="checklist" v-model="groupTitle">
              <el-checkbox
                :label="item.group"
                v-for="(item, index) in cameraList"
                :key="index"
                @change="
                  handleCheckedChange(item.type, item.group, index, $event)
                "
              >
                {{ item.group }}({{ item.count }})
              </el-checkbox>
              <el-checkbox label="追溯系统" @change="changeReview($event)">
                追溯系统
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <ReviewHis :showId="showId" :plotId="plotId"></ReviewHis>
        <MapIntroCard :showId="showId"></MapIntroCard>
      </div>
    </div>
    <!-- 信息窗口 -->
    <div
      id="info-card"
      class="info-card win-box"
      :style="
        'background: url(' +
        $imgOnlineUrl +
        '/index/infoWin.png) center center no-repeat'
      "
    >
      <div class="title tops-box">
        <div class="title one-txt-cut">{{ infoWindowObj.title }}</div>
        <div class="status-box" :class="'active' + infoWindowObj.online">
          {{ infoWindowObj.online ? "在线" : "离线" }}
        </div>
      </div>
      <div class="bom-box">
        <p>
          {{ infoWindowObj.title }}&nbsp;&nbsp;设备状态：{{
            infoWindowObj.online ? "在线" : "离线"
          }}
        </p>
        <p>编号：{{ infoWindowObj.id }}</p>
      </div>
      <span class="top"></span>
    </div>
    <MapCropCard :showId="showId"></MapCropCard>
    <ReviewPlant
      :showId="showId"
      :plotId="plotId"
      :plotForm="plotForm"
    ></ReviewPlant>
    <!-- <ReviewTraceability :showId="showId" :plotId="plotId"></ReviewTraceability> -->

    <MapFilter
      @changeVillage="changeVillage"
      ref="MapFilter"
      :showId="showId"
    ></MapFilter>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import * as turf from "@turf/turf";

import {
  getAllMarkDataApi,
  getEquipmentChildListApi,
  postSetEquipmentChildListInfoApi,
  getEquipmentInfoApi,
  getTownInfoApi,
  getPlotInfoApi,
} from "@/request/api";
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Style, Fill, Text, Stroke, Icon } from "ol/style";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import Collection from "ol/Collection";
import Overlay from "ol/Overlay";
import * as Interaction from "ol/interaction";
import hanshan from "@/assets/json/hanshan.json";
// import hanshan from "@/assets/json/宿州市.json";

import Image from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";
export default {
  components: {
    MapWeather: () => import("@/components/MapWeather.vue"),
    MapAllInfo: () => import("@/components/MapAllInfo.vue"),
    MapLands: () => import("@/components/MapLands.vue"),
    MapCamera: () => import("@/components/MapCamera.vue"),
    MapCollection: () => import("@/components/MapCollection.vue"),
    MapEquipment: () => import("@/components/MapEquipment.vue"),
    MapIntroCard: () => import("@/components/MapIntroCard.vue"),
    MapCropCard: () => import("@/components/MapCropCard.vue"),
    MapFilter: () => import("@/components/MapFilter.vue"),
    ReviewPlant: () => import("@/components/ReviewPlant.vue"),
    // ReviewTraceability: () => import("@/components/ReviewTraceability.vue"),
    ReviewHis: () => import("@/components/ReviewHis.vue"),
  },
  data() {
    return {
      hanShanSource: new VectorSource({
        features: new GeoJSON().readFeatures(hanshan, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        }),
      }),
      cameraTitle: "",
      defaultImgs: this.$imgOnlineUrl + "/configure/farm/icon8.png",
      groupTitle: [],
      cameraList: [],
      plotForm: {
        company_name: "",
        phone: "",
        area: "",
        photo: "",
        title: "",
        desc: "",
        product_list: [],
      },
      marker: null,
      infoBox: null,
      showId: "",
      contId: "",
      infoWindowObj: {
        title: "",
        status: "",
      },
      overLayer: null,
      iconMarkLayer: null,
      infoTitle: "",
      weatherObj: {},
      titleLayer: {},
      polygonLayer: null,
      myPolygonSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
      polygonsData: [],
      polygonBackupsData: [],
      videoUrl: undefined,
      colDataList: [],
      table_id: "",
      child_id: "",
      online: 1,
      data_value: "",
      data_type: "",
      weatherCount: "",
      olMap: {},
      titleFeatures: [],
      allDataList: {},
      vsource: new VectorSource({}),
      centerVal: 0,
      plotId: 0,
      setTime: null,
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      userInfo: (state) => state.user.userInfo,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },

  created() {
    this.getAllMarkData();
  },
  watch: {
    plotId: {
      handler(newVal) {
        this.getPlotInfoApi(newVal);
      },
    },
  },
  beforeDestroy() {
    if (this.setTime) {
      clearInterval(this.setTime);
      this.setTime = null;
    }
  },
  methods: {
    ...mapMutations("app", ["SET_CAMERALIST"]),
    async getPlotInfoApi(plot_id) {
      const { code, results } = await getPlotInfoApi({
        manor_id: this.farmID,
        plot_id,
      });
      if (code === 0) {
        this.plotForm = results;
      }
    },
    changeReview(e) {
      if (e) {
        this.showId = "review";
      } else {
        this.showId = "";
      }
    },
    async changeVillage(town_id, scope_id) {
      this.$forceUpdate();
      const { code, results } = await getTownInfoApi({
        manor_id: this.farmID,
        town_id,
        scope_id,
      });
      if (code != 0) return;

      const {
        scope_mark_data,
        scope_name,
        scope_body_color,
        scope_border_color,
      } = results[0].items[0];
      this.polygonsData = [[]];
      this.polygonsData[0].push({
        title: scope_name,
        color: [scope_body_color, scope_border_color],
        coord: JSON.parse(scope_mark_data),
      });

      results[0].items.forEach((t) => {
        const { mark_data, title, body_color, border_color } = t;
        if (mark_data) {
          this.polygonsData[0].push({
            title: title,
            color: [body_color, border_color],
            coord: JSON.parse(mark_data),
          });
        }
      });
      this.clearDraw();
      this.addTitles();
      this.showPolygon();
    },
    resetMapZoom() {
      // this.showId = "";
      this.polygonsData = this.polygonBackupsData;
      this.clearDraw();
      this.addTitles();
      this.showPolygon();
      this.$refs.MapFilter.town_id = "";
      this.$refs.MapFilter.scope_id = "";
      this.$refs.MapFilter.villageList = [];
      this.$refs.MapFilter.detail = { count: 0, area: 0 };
      let position = this.farmInfo.position;
      position = position.split(",");
      window.olMap.getView().animate({
        rotation: undefined,
        duration: 500,
        center: [+position[0], +position[1]],
        zoom: 11,
        maxZoom: 18,
      });
    },
    // 眼
    initMap() {
      let position = this.farmInfo.position;
      position = position.split(",");

      this.iconMarkLayer = new VectorLayer({
        zIndex: 3,
        source: this.vsource,
      });
      window.olMap = new Map({
        target: "map",
        layers: [
          // 航拍
          new Image({
            source: new ImageStatic({
              url: `${this.$imgOnlineUrl}/hanshan/result2.png`,
              projection: "EPSG:4326",
              imageExtent: [
                118.047484561, 31.465395246, 118.051304645, 31.474541223,
              ],
            }),
            zIndex: 4,
            preload: Infinity,
          }),
          new Image({
            source: new ImageStatic({
              url: `${this.$imgOnlineUrl}/hanshan/result1.png`,
              projection: "EPSG:4326",
              imageExtent: [
                118.112079615, 31.5147786, 118.117627667, 31.518332318,
              ],
              // imageExtent: [118.112079615,31.514778600,118.117627667,31.518332318],
            }),
            zIndex: 4,
            preload: Infinity,
          }),
          // 含山县
          new VectorLayer({
            zIndex: 3,
            maxZoom: 11,
            source: this.hanShanSource,
            style: new Style({
              fill: new Fill({
                color: "rgba(255,255,255,.3)",
                // color: "rgba(255,255,255,.2)",
              }),
              stroke: new Stroke({
                width: 4,
                color: "#0f343d",
                // color: "red",
              }),
            }),
          }),
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer,
          this.titleLayer,
          this.iconMarkLayer,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+position[0], +position[1]],
          zoom: this.farmInfo.zoom,
          maxZoom: 18,
          smoothResolutionConstraint: false,
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new Interaction.defaults({
          doubleClickZoom: false,
        }),
      });
      this.infoBox = document.getElementById("info-card");
      this.overLayer = new Overlay({
        element: this.infoBox,
        positioning: "center-center",
        offset: [0, -15],
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.hoverPoint();
      this.clickPoint();
    },
    // 清除多边形
    clearDraw() {
      this.myPolygonSource.clear();
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: x,
        }),
        stroke: new Stroke({
          width: 3,
          color: y,
        }),
      });
      return style;
    },
    showPolygon(index = "wu") {
      let hanshanpolygonsData = [];
      if (index == "wu") {
        hanshanpolygonsData = this.polygonsData;
      } else {
        hanshanpolygonsData = [this.polygonsData[index]];
      }
      hanshanpolygonsData.forEach((item) => {
        let array = item;
        let features = [];
        for (let i = 0; i < array.length; i++) {
          let coord = array[i].coord || [];
          let x = array[i].color[0];
          let y = array[i].color[1];
          let z = array[i].name;
          let f = new Feature({
            geometry: new Polygon([coord]),
            type: "isPolygon",
            id: array[i].id,
            title: array[i].title,
          });
          f.setStyle(this.featureStyle(x, y));
          f.setProperties({
            name: z,
          });
          features.push(f);
        }
        this.myPolygonSource.addFeatures(features);
        this.polygonLayer = new VectorLayer({
          zIndex: 3,
          minZoom: 8,
          source: this.myPolygonSource,
        });
      });
    },
    // 点层
    cityPoint(list) {
      let features = [];
      let data = list;
      for (let i = 0; i < data.length; i++) {
        let feature = new Feature({
          geometry: new Point(data[i].position),
          type: "isPoint",
          citydata: data[i],
          // id: data[i].id,
          title: data[i].title,
        });
        feature.setStyle(this.pointStyle(data[i].online, data[i].iconType));
        features.push(feature);
      }
      this.vsource.addFeatures(features);
    },

    pointStyle(online, iconType) {
      let imgUrl = iconType + online + ".png";
      let src = this.$imgOnlineUrl + "/index/" + imgUrl;
      let Styles = [];
      Styles.push(
        new Style({
          // 设置图标
          image: new Icon({
            src,
            anchor: [0.5, 0.5],
            scale: 1,
          }),
        })
      );
      return Styles;
    },
    hoverPoint() {
      window.olMap.on("pointermove", (e) => {
        if (e.dragging) return;
        let feature = window.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          let cityInfo = feature.get("citydata");
          let items = {};
          if (!cityInfo) return;

          switch (cityInfo.icon) {
            case "video":
              items = this.cameraList.find(
                (item) => item.group == "视频监控"
              ).items;
              break;
            case "grow":
              items = this.cameraList.find(
                (item) => item.group == "苗情监测"
              ).items;
              break;
            case "weather":
              items = this.cameraList.find(
                (item) => item.group == "气象采集"
              ).items;
              break;
            case "control":
              items = this.cameraList.find(
                (item) => item.group == "大棚控制"
              ).items;
              break;
            case "water":
              items = this.cameraList.find(
                (item) => item.group == "水质检测"
              ).items;
              break;
            case "soil":
              items = this.cameraList.find(
                (item) => item.group == "土壤检测"
              ).items;
              break;
            case "fertilizer":
              items = this.cameraList.find(
                (item) => item.group == "水肥一体"
              ).items;
              break;
            case "heat":
              items = this.cameraList.find(
                (item) => item.group == "加热机组"
              ).items;
              break;
            case "machine":
              items = this.cameraList.find(
                (item) => item.group == "农机监测"
              ).items;
              break;
            case "gate":
              items = this.cameraList.find(
                (item) => item.group == "阀门控制"
              ).items;
              break;
            case "insect":
              items = this.cameraList.find(
                (item) => item.group == "虫情监测"
              ).items;
              break;
            case "feed":
              items = this.cameraList.find(
                (item) => item.group == "智能投料"
              ).items;
              break;
          }
          this.infoWindowObj = items.find((item) => item.id == cityInfo.id);
          this.overLayer.setPosition(cityInfo.position);
        } else {
          this.overLayer.setPosition(undefined);
        }
        window.olMap.addOverlay(this.overLayer);
      });
    },
    clickPoint() {
      window.olMap.on("singleclick", (e) => {
        console.log(e.coordinate);
        let feature = window.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          this.plotId = feature.get("id") ? feature.get("id") : this.plotId;
          window.olMap.getView().animate({
            center: e.coordinate,
            zoom: 16,
            rotation: undefined,
            duration: 500,
          });
          if (this.showId == "review") return;
          if (feature.get("type") === "isPolygon") {
            this.showId = "地块";
          } else if (feature.get("type") === "isPoint") {
            this.contId = feature.values_.citydata.id;
            this.showId = feature.values_.citydata.icon;
            if (this.showId === "video" || this.showId === "grow") {
              this.videoUrl = `https://hs.darsing.net/project/digital2/video/address?eid=${this.contId}&return_type=iframe`;
            } else {
              this.getEquipmentChildListApi(this.contId);
            }
            this.cameraTitle = this.infoWindowObj.title;
          } else {
            this.showId = "all";
          }
        }
      });
    },
    // 添加标题开始
    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "rgba(22,133,144,.3)",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    addTitles() {
      this.titleLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 13,
        source: new VectorSource({
          features: new Collection(
            this.titleFeatures.map((featureOptions) => {
              const feature = new Feature({
                geometry: featureOptions.geometry,
              });
              feature.setStyle(this.createTitleStyle(featureOptions));
              return feature;
            })
          ),
          format: new GeoJSON(),
        }),
      });
    },
    // 选择类型
    handleCheckedChange(type, group, index, value) {
      if (value) {
        this.polygonsData = this.polygonBackupsData;
        if (type == "plot") {
          if (index == 0) {
            this.showPolygon("wu");
          } else {
            this.showPolygon(index);
          }
          this.addTitles();
        } else {
          this.cityPoint(this.allDataList[group]);
        }
      } else {
        if (type == "plot") {
          if (index == 0) {
            this.clearDraw();
          } else {
            let clearPolygonArr = this.polygonLayer
              .getSource()
              .getFeatures()
              .filter((feature) => {
                return feature.values_.title === group;
              });
            clearPolygonArr.forEach((item) => {
              this.polygonLayer.getSource().removeFeature(item);
            });
          }
        } else {
          // 清除对应的点标记
          let clearArr = this.iconMarkLayer
            .getSource()
            .getFeatures()
            .filter((feature) => {
              return feature.values_.title === group;
            });
          clearArr.forEach((item) => {
            this.iconMarkLayer.getSource().removeFeature(item);
          });
        }
        this.$forceUpdate();
      }
    },
    // 获取设备类型
    async getAllMarkData() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.cameraList = results;
      this.cameraList[0].count =
        this.cameraList[0].count + this.cameraList[1].count;
      this.weatherCount = this.cameraList.findIndex(
        (item) => item.group === "气象采集"
      );
      this.cameraList.forEach((item, index) => {
        if (item.group === "虫情监测") {
          item.count >= 3 ? (item.count = item.count / 3) : "";
        }
        this.groupTitle.push(item.group);
        if (item.type != "plot") {
          this.allDataList[item.group] = [];
          item.items.forEach((item2) => {
            let obj = {
              position: "",
              title: "",
              img: "",
              online: 1,
            };
            let iconType = "";
            if (item2.icon === "video") {
              iconType = "video";
            } else if (item2.icon === "weather") {
              iconType = "weather";
            } else {
              iconType = "water";
            }
            obj.title = item.group;
            obj.id = item2.id;
            obj.iconType = iconType;
            obj.icon = item2.icon;
            obj.position = [
              +item2.mark_data.split(",")[0],
              +item2.mark_data.split(",")[1],
            ];
            obj.img = `${this.$imgOnlineUrl}/index/${iconType}${item2.online}.png`;
            this.allDataList[item.group].push(obj);
            this.$forceUpdate();
            this.cityPoint(this.allDataList[item.group]);
          });
          this.$forceUpdate();
        } else {
          this.polygonsData[index] = [];
          this.polygonBackupsData[index] = [];
          item.items.forEach((item2) => {
            if (item2.mark_type == 3 && item2.mark_data) {
              let polygonsItemObj = { coord: [] };
              if (typeof item2.mark_data === "string") {
                item2.mark_data = JSON.parse(item2.mark_data);
              }
              item2.mark_data.forEach((item3) => {
                polygonsItemObj.coord.push([item3[0], item3[1]]);
              });
              polygonsItemObj.color = [item2.body_color, item2.border_color];
              polygonsItemObj.name = `polygon${item2.id}`;
              polygonsItemObj.id = item2.id;
              polygonsItemObj.title = item.group;
              let polygon = turf.polygon([item2.mark_data]);
              this.centerVal = turf.centroid(polygon).geometry.coordinates;
              this.polygonsData[index].push(polygonsItemObj);
              this.polygonBackupsData[index].push(polygonsItemObj);
              let titleMarkerObj = {
                text: item2.title,
                geometry: new Point(this.centerVal),
              };
              this.titleFeatures.push(titleMarkerObj);
            }
          });
          this.$forceUpdate();
        }
      });

      this.addTitles();
      this.showPolygon();
      this.setTime = setTimeout(() => {
        this.initMap();
      }, 80);
    },
    changeSwitch(e, id) {
      this.child_id = id;
      this.data_type = 1;
      this.data_value = e ? 1 : 0;
      this.postSetEquipmentChildListInfoApi();
    },
    async getEquipmentInfoApi_status_online(title, table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: table_id,
        act: "status",
      });
      if (code != 0) return;
      if (results.online === undefined) {
        results.online = 0;
      }
      return results.online;
    },
    async getEquipmentInfoApi_status(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: table_id,
        act: "status",
      });
      if (code === 0) {
        for (let key in this.colDataList) {
          let data1 = this.colDataList[key].number;
          for (let keys in results.data) {
            if (results.data[keys].number == data1) {
              this.colDataList[key].last_data = results.data[keys].last_data;
            }
          }
        }
        this.$forceUpdate();
      }
    },
    async postSetEquipmentChildListInfoApi() {
      const { code, msg } = await postSetEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.contId,
        child_id: this.child_id,
        data_type: this.data_type,
        data_value: this.data_value,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已修改！",
          type: "success",
        });
      } else {
        this.$notify({
          title: "提示",
          message: msg,
        });
      }
    },
    async getEquipmentChildListApi(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
      });
      if (code === 0) {
        this.colDataList = results;
        this.getEquipmentInfoApi_status(table_id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  width: 100%;
  height: 100%;
  background: #051218;
  /deep/.GvolContainer {
    width: 100vw !important;
    height: 100vh !important;
    position: relative;
    color: #fff;
    .reset {
      width: 26px;
      height: 26px;
      position: absolute;
      right: 80px;
      bottom: 100px;
      cursor: pointer;
      z-index: 9999;
    }
  }
  .equipment,
  .collection,
  .weather {
    width: 282px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top: 6px solid #125397;
    padding: 23px 13px;
    background-color: #0f243d;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 106px;
    left: 78px;
    z-index: 1999;
    transition: all 0.5s;
    p {
      margin: 0 0 10px 0;
      &:first-of-type {
        font-weight: bold;
        font-size: 18px;
        margin: 0 0 20px 0;
      }
    }
    .eq-title {
      color: #3e90e5;
      font-size: 16px;
      margin-bottom: 17px;
      text-align: center;
    }

    .eq-lists {
      display: flex;
      width: 100%;
      flex-direction: column;

      .eq-items {
        width: 100%;
        height: 41px;
        background: rgba(62, 144, 229, 0.25);
        border: 1px solid #3e90e5;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 6px 14px;
        justify-content: space-between;
        margin-bottom: 14px;

        .left-div {
          display: flex;
          align-items: center;
          .icon1-img {
            width: 30px;
            height: 30px;
            margin-right: 16px;
          }
        }
        .name,
        .val {
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
        .my-switch {
          /deep/.el-switch__core {
            background-color: #d8e5f0;
          }

          /deep/.el-switch__core:after {
            background-color: #a6aeb5;
          }
        }

        .my-switch.active {
          /deep/.el-switch__core:after {
            background-color: #2878ff;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .map-r {
    width: 315px;
    position: absolute;
    z-index: 1998;
    top: 86px;
    right: 80px;
    .popup-base {
      width: 315px;
      margin-bottom: 20px;
      .title {
        width: 100%;
        height: 32px;
        font-size: 14px;
        padding: 8px 10px;
        box-sizing: border-box;
        background: rgba(21, 92, 165, 0.9);
        text-align: left;
        display: flex;

        justify-content: space-between;
        align-items: center;
        border-radius: 4px 4px 0 0;

        div {
          flex: 1;
          span {
            background: #0e4680;
            border-radius: 10px;
            font-size: 8px;
            padding: 2px 15px;
            margin: 0 4px 0px 0;
          }
        }
      }
      .check-out-div {
        width: 100%;
        padding: 8px 16px;
        background-color: #0f243d;
        text-align: left;

        .checklist.el-checkbox-group {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          /deep/.el-checkbox {
            // width: 50%;
            color: #fff;
            margin: 0 4px 10px 0;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
          }
          /deep/.el-checkbox__label {
            font-size: 14px;
          }
        }
      }

      a {
        color: #fff;
        padding: 8px 0;
        display: inline-block;
      }
    }
  }
}
.win-box {
  .tops-box {
    width: 100%;
    height: 51px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 34px;
    display: flex;
    align-items: center;

    .title {
      color: #3e90e5;
      font-size: 16px;
      font-weight: bold;
      line-height: 40px;
      margin-right: 10px;
    }

    .status-box {
      width: 45px;
      height: 17px;
      border-radius: 17px;
      border: 1px solid #08a600;
      line-height: 14px;
      text-align: center;
      font-size: 11px;
      color: #08a600;
      &.active0 {
        color: red !important;
        border-color: red !important;
      }
    }
  }

  .bom-box {
    width: 100%;
    height: calc(100% - 51px);
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p {
      font-size: 14px;
      color: #fff;
      margin-bottom: 19px;
    }
  }
}

.info-card {
  width: 320px;
  height: 144px;
  position: absolute;
  bottom: 18px;
  left: -160px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);

  .title {
    height: 40px;
    color: #ffffff;

    span.title_name {
      position: relative;
      top: 7px;
      left: 10px;
      font-size: 18px;
    }
  }

  span.top {
    width: 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    border-width: 10px;
    border-style: solid dashed dashed;
    border-color: #051627 transparent transparent;
    transform: translateX(-50%);
    left: 50%;
    bottom: -13px;
  }
}
</style>
